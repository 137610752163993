import React from "react";
import { Carousel } from "react-responsive-carousel";
import styles from "./fourthResort.module.scss";
import Downbar from "../../../ui-elements/blocks/downbar/Downbar";
import PhoneIcon from "../../../icons/PhoneIcon";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NextBtn from "../../../icons/NextBtn";
import BackBtn from "../../../icons/BackBtn";
import photo1 from "../../../../assets/img/fourthblock/photo1.png";
import photo2 from "../../../../assets/img/fourthblock/photo2.png";
import photo3 from "../../../../assets/img/fourthblock/photo3.png";
import photo4 from "../../../../assets/img/fourthblock/photo4.png";
import photo5 from "../../../../assets/img/fourthblock/photo5.png";

type RenderArrowFunction = (
  onClickHandler: () => void,
  hasArrow: boolean,
  label: string,
) => JSX.Element;

function FourthResortComponent() {
  const images = [photo1, photo2, photo3, photo4, photo5];

  const renderCustomPrevArrow: RenderArrowFunction = (
    onClickHandler,
    hasPrev,
    label,
  ) => (
    <button
      className={`${styles.customArrow} ${styles.customPrevArrow}`}
      onClick={onClickHandler}
      disabled={!hasPrev}
      aria-label={label}
    >
      <BackBtn />
    </button>
  );

  const renderCustomNextArrow: RenderArrowFunction = (
    onClickHandler,
    hasNext,
    label,
  ) => (
    <button
      className={`${styles.customArrow} ${styles.customNextArrow}`}
      onClick={onClickHandler}
      disabled={!hasNext}
      aria-label={label}
    >
      <NextBtn />
    </button>
  );

  return (
    <div className={styles.fourthResortContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.contentWrapper}>
          <div className={styles.contentBox}>
            <div className={styles.title}>VATICI CAMPING & RESORT</div>
            <div className={styles.description}>
              Bucură-te de tradiții și ospitalitate moldovenească la resortul
              nostru din Vatici. Explorează peisajele pitorești ale Orheiului și
              bucură-te de drumeții, ciclism sau alte activități în aer liber.
            </div>
            <a href="tel:+37368686123" className={styles.callBtn}>
              <div className={styles.callNow}>SUNĂ ACUM</div>
              <PhoneIcon />
            </a>
          </div>
        </div>

        <div className={styles.mediaContainer}>
          <div className={styles.carousel}>
            <Carousel
              emulateTouch
              animationHandler="slide"
              showThumbs={false}
              infiniteLoop
              showStatus={false}
              showArrows
              autoPlay
              stopOnHover
              dynamicHeight
              swipeScrollTolerance={20}
              renderArrowPrev={renderCustomPrevArrow}
              renderArrowNext={renderCustomNextArrow}
            >
              {images.map((imageUrl, index) => (
                <div key={index}>
                  <img
                    className={styles.image}
                    src={imageUrl}
                    alt={`Scenic view of Vatici Resort ${index + 1}`}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <div className={styles.mapContainer}>
            <iframe
              title={"Vatici Resort Map"}
              loading={"lazy"}
              allowFullScreen={false}
              className={styles.map}
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=47.346187205311104,%2028.641778947209005+(Vatici%20Resort)&amp;t=k&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps vehicle tracker</a>
            </iframe>
          </div>
        </div>
      </div>
      <Downbar />
    </div>
  );
}

export default FourthResortComponent;
