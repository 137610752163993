import React from "react";
import styles from "./secondResort.module.scss";
import PhoneIcon from "../../../icons/PhoneIcon";
const decoration1 = require("../../../../assets/img/secondblock/decoration1.png");
const photo1 = require("../../../../assets/img/secondblock/photo1.png");
const decoration2 = require("../../../../assets/img/secondblock/decoration2.png");
const photo2 = require("../../../../assets/img/secondblock/photo2.png");

function SecondResortComponent() {
  return (
    <div className={styles.secondResortContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.contentWrapper}>
          <div className={styles.contentBox}>
            <div className={styles.title}>Evadare relaxantă</div>
            <div className={styles.description}>
              Descoperă un rai ascuns în inima Moldovei, unde te poți bucura de
              liniște, natură și o atmosferă relaxantă.
            </div>
            <a href="tel:+37368686123" className={styles.callBtn}>
              <div className={styles.callNow}>SUNĂ ACUM</div>
              <PhoneIcon />
            </a>
          </div>
          <div className={styles.imageBox}>
            <img
              className={styles.decorationOne}
              style={{
                position: "absolute",
                top: -290,
                left: -250,
              }}
              src={decoration1}
              alt="decoration"
            />
            <img
              className={styles.image}
              src={photo1}
              style={{
                position: "absolute",
                top: -170,
                left: -30,
                zIndex: 10,
              }}
              alt="beci"
            />
          </div>
        </div>
        <div className={`${styles.contentWrapper} ${styles.column}`}>
          <div className={styles.imageBox}>
            <img
              className={styles.decorationTwo}
              style={{ position: "absolute", top: -380, left: -100 }}
              src={decoration2}
              alt="decoration"
            />
            <img
              className={styles.image}
              src={photo2}
              style={{
                position: "absolute",
                top: -210,
                left: -30,
                zIndex: 10,
              }}
              alt="albine"
            />
          </div>
          <div className={styles.contentBox}>
            <div className={`${styles.title} ${styles.title2}`}>Tradiție</div>
            <div className={styles.description}>
              Savurează bucate tradiționale delicioase, pregătite cu grijă după
              rețete autentice, folosind ingrediente locale proaspete și de cea
              mai bună calitate, pentru a oferi o experiență culinară care
              îmbină gusturile și aromele bogate ale regiunii.
            </div>
            <a href="tel:+37368686123" className={styles.callBtn}>
              <div className={styles.callNow}>SUNĂ ACUM</div>
              <PhoneIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondResortComponent;
